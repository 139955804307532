import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const Background = styled.div`
  background-image: url(${props => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${props =>
    props.positionDesktop ? props.positionDesktop : "center"};
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => (props.opacity ? props.opacity : null)};
  }
  @media (max-width: 991px) {
    background-position: ${props =>
      props.positionMobile ? props.positionMobile : "center"};
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const Baseline = styled.p`
  color: ${props => (props.color ? props.color : "#4f5054")};
  line-height: 26px;
  font-size: 18px;
  margin-bottom: 2rem;
  @media (max-width: 991px) {
    line-height: 22px;
  }
`;

const Banner = ({
  title,
  baseline,
  categorie,
  niveau,
  imageBanner,
  positionDesktop,
  positionMobile,
  color,
  opacity,
  tag
}) => {
  return (
    <section id="page-title" className="page-title banner-blog-details">
      <Background
        className="container-fluid "
        positionDesktop={positionDesktop}
        positionMobile={positionMobile}
        opacity={opacity}
      >
        <Img fluid={imageBanner} alt="Azerty Studio Banniere" />
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-2">
              <div className="title-content">
                <div className="title-heading">
                  <h1 className={tag}>{title}</h1>
                </div>
                <Baseline color={color}>{baseline}</Baseline>
                <div className="tag-bloc">
                  <span className="tag-article">{categorie}</span>
                  <span className="tag-article">{niveau}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Background>
    </section>
  );
};

export default Banner;
