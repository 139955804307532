import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Link from "gatsby-link";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/blog/banner";

import Vincent from "../assets/images/team/vincent-mini2.jpg";
import SideBar from "../components/blog/sidebar";

const VincentImg = styled.img`
  max-height: 150px;
  max-width: 100%;
  width: auto;
`;

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const allMarkdownRemark = data.allMarkdownRemark.edges;

  const { markdownRemark } = data; // data.markdownRemark holds our post data
  let allMarkdownRemarkFiltered = allMarkdownRemark.filter(elem => {
    return elem.node.frontmatter.title !== markdownRemark.frontmatter.title;
  });
  if (allMarkdownRemarkFiltered.length === 3) {
    allMarkdownRemarkFiltered.pop();
  }
  const { frontmatter, html } = markdownRemark;
  const {
    title,
    author,
    authorDescription,
    linkedin,
    categorie,
    niveau
    // date
  } = frontmatter;
  return (
    <Layout>
      <SEO title={title} description={data.markdownRemark.excerpt} />
      <Banner
        title={title}
        imageBanner={data.imageBanner.childImageSharp.fluid}
        tag="blog-banner"
        // baseline={date}
        categorie={categorie}
        niveau={niveau}
      />

      <section id="blog-single-standard" className="blog single-post">
        <div className="container">
          <div className="row">
            <SideBar />

            <div className="col-sm-12 col-md-8 col-lg-8 mb-30-xs mb-30-sm">
              <div className="entry">
                <div className="entry-post">
                  {/* <div className="entry-meta ">
                    <span>Rédigé le {date} par </span>
                    <a href={linkedin}>{author}</a>
                  </div>
                  <div className="entry-meta ">
                    <span className="tag-article">{categorie}</span>
                    <span className="tag-article">{niveau}</span>
                  </div> */}
                  <div
                    className="entry-content"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </div>
                <Link
                  className="btn btn-ternary btn-block btn-hover mb-30"
                  to="/contact"
                >
                  Contactez-nous
                </Link>
              </div>

              {/* <div className="entry-share">
                <span className="share-title">Partagez cet article&nbsp;:</span>
                <span className="share-links">
                  <a className="share-facebook" href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a className="share-twitter" href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a className="share-pinterest" href="#">
                    <i className="fab fa-linkedin-in" />
                  </a>
                </span>
              </div> */}
              {/* <div className="entry-prev-next">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="entry-prev bg-gray">
                      <div className="entry-prev-icon">
                        <a href="#">
                          <i className="fal fa-long-arrow-alt-left fa-2x" />
                        </a>
                      </div>
                      <div className="entry-prev-content">
                        <h6>précédent</h6>
                        <a className="entry-link" href="#">
                          Check out books
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="entry-next bg-gray">
                      <div className="entry-next-content text-right">
                        <h6>prochain</h6>
                        <a className="entry-link" href="#">
                          How to organize books?
                        </a>
                      </div>
                      <div className="entry-next-icon">
                        <a href="#">
                          <i className="fal fa-long-arrow-alt-right fa-2x" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="entry-author bg-gray">
                <div className="entry-widget-title clearfix">
                  À propos de l'auteur
                </div>
                <div className="entry-widget-content">
                  <div className="entry-author-img">
                    <VincentImg src={Vincent} alt="Vincent Pélage" />
                  </div>
                  <div className="entry-author-bio">
                    <h6>{author}</h6>
                    <p>{authorDescription}</p>
                    <div className="entry-author-social">
                      <a
                        href={linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Linkedin
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="entry-related bg-gray">
                <div className="entry-widget-title clearfix">
                  Derniers articles
                </div>
                <div className="entry-widget-content text-center">
                  <div className="row">
                    {allMarkdownRemarkFiltered.map((elem, key) => {
                      return (
                        <div key={key} className="col-sm-12 col-md-6 col-lg-6">
                          <div className="entry">
                            <div className="entry-featured">
                              <a href={elem.node.frontmatter.path}>
                                <Img
                                  fluid={
                                    elem.node.frontmatter.image.childImageSharp
                                      .fluid
                                  }
                                  alt={elem.node.frontmatter.title}
                                />
                              </a>
                            </div>
                            {/* <div className="entry-date text-center">
                              <span>{elem.node.frontmatter.date}</span>
                            </div> */}
                            <div className="entry-title text-center">
                              <h3>
                                <a href={elem.node.frontmatter.path}>
                                  {elem.node.frontmatter.title}
                                </a>
                              </h3>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            path
            title
            image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            author
            linkedin
            categorie
            niveau
          }
        }
      }
    }
    markdownRemark(
      frontmatter: { template: { eq: "blog" }, path: { eq: $path } }
    ) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: "fr")
        path
        title
        author
        authorDescription
        linkedin
        categorie
        niveau
      }
      excerpt
    }
    imageBanner: file(
      relativePath: { eq: "background/bannerBlogDetailsOriginal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
